<template>
  <div class="liveSession-default-user-detail-page">
    <NavbarDefaultData :callbackOnBtn="submit" />

    <v-form ref="form" class="liveSession-default-user-detail-page__form">
      <v-card-title>Dati Evento</v-card-title>
      <v-card>
        <v-text-field v-model="model.title" label="Title"></v-text-field>
        <v-text-field
          v-model="model.description"
          label="Description"
        ></v-text-field>

        <v-select
          v-model="model.idEvent"
          label="Event"
          item-text="label"
          item-value="value"
          :items="eventsOptions"
          v-on:change="setAvailableParentsOfEvent(model.idEvent)"
        ></v-select>
        <v-select
          v-model="model.idParent"
          label="Location"
          item-text="label"
          item-value="value"
          :items="availableParentsOptions"
        ></v-select>
        <v-select
          v-model="model.openingOption"
          label="Opening options"
          item-text="label"
          item-value="value"
          :items="openingOptionsOptions"
        ></v-select>
        <v-select
          v-model="model.platform"
          label="Platform"
          item-text="label"
          item-value="value"
          :items="platformsOptions"
        ></v-select>
        <v-text-field
          v-model="model.speakerLink"
          label="Speaker link"
        ></v-text-field>
        <v-text-field
          v-model="model.publicLink"
          label="Public link"
        ></v-text-field>
        <v-text-field
          v-model="model.duration"
          label="Duration"
          type="number"
          step="any"
          min="0"
          suffix="min"
        ></v-text-field>
        <v-switch
          v-if="showUserChoice"
          v-model="model.enableUserChoice"
          label="Enable User Choice"
        ></v-switch>
      </v-card>
      <v-btn
        class="liveSession-default-user-detail-page__delete"
        @click.prevent="clear"
        :disabled="isDisabledClear"
      >
        clear
      </v-btn>
    </v-form>
    <Loading v-if="isLoading" />
  </div>
</template>


<script>
import Base from "@/mixins/base.js";
import Component, { mixins } from "vue-class-component";
import Loading from "@/components/loading";
import Window from "@/components/dom/window";
import NavbarDefaultData from "@/components/navbar/NavbarDefaultData";
import { liveSessionDefaultUserService } from "@/services/liveSessionDefaultUserService.js";
import { liveSessionService } from "@/services/liveSessionService.js";
import { localStorageService } from "@/services/localStorageService.js";
import { eventService } from "@/services/eventService.js";
import { notificationService } from "@/services/notificationService.js";

@Component({
  components: {
    Loading,
    Window,
    NavbarDefaultData,
  },
  watch: {},
})
export default class LiveSessionDefaultUser extends mixins(Base) {
  isLoading = true;
  model = {
    description: null,
    enableUserChoice: false,
    id: null,
    idEvent: null,
    idParent: null,
    idUser: null,
    openingOption: null,
    platform: null,
    title: null,
    availableParents: null,
    duration: null,
    speakerLink: null,
    publicLink: null,
  };
  isLoading = false;
  isDisabledSubmit = false;
  isDisabledClear = false;
  platforms = [];
  openingOptions = [];
  eventList = [];
  availableParents = [];

  get eventsOptions() {
    return this.eventList.map((item) => {
      return {
        label: item.code,
        value: item.id,
      };
    });
  }

  get platformsOptions() {
    return this.platforms
      .map((item) => {
        return {
          value: item,
          label: item,
        };
      })
      .filter((item) => item.value == "Skymeeting" || item.value == "Teams");
  }

  get openingOptionsOptions() {
    return this.openingOptions.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  }

  get availableParentsOptions() {
    return this.availableParents.map((item) => {
      return {
        value: item.id,
        label: `${item.code} - (${item.parentType})`,
      };
    });
  }

  get currentUserId() {
    return (
      this.$store.state.authStore.impersonatedUser ??
      localStorageService.getLoggedUser()?.id
    );
  }

  get showUserChoice() {
    return this.model.platform == "Teams";
  }

  async submit() {
    this.isLoading = true;
    this.isDisabledSubmit = true;
    try {
      await this.upsertModel();
      this.isLoading = false;
      notificationService.success("Salvataggio andato a buon fine");
      setTimeout(() => {
        this.$router.push("/");
      }, 500);
    } catch (error) {
      this.isLoading = false;
      notificationService.fail(error.description);
    }
    this.isDisabledSubmit = false;
  }

  async upsertModel() {
    this.model.idUser = this.currentUserId;
    if (this.model?.id) {
      await liveSessionDefaultUserService.update(this.model.id, this.model);
    } else {
      delete this.model.id;
      await liveSessionDefaultUserService.create(this.model);
    }
  }

  async handleModelLoading() {
    this.isLoading = true;
    this.model = await liveSessionDefaultUserService.readByUserId(
      this.currentUserId
    );
    if (!this.model) {
      this.resetModel();
    } else {
      this.setAvailableParentsOfEvent(this.model.idEvent);
    }
    this.isLoading = false;
  }

  async setAvailableParentsOfEvent(idEvent) {
    this.availableParents = await liveSessionService.getParentsByEvent(idEvent);
  }

  async clear($event) {
    $event.preventDefault();
    this.isLoading = true;
    this.isDisabledClear = true;
    try {
      if (this.model?.id) {
        await liveSessionDefaultUserService.delete(this.model.id);
      }
      this.isLoading = false;
      this.resetModel();
      notificationService.success("Eliminazione andata a buon fine");
      setTimeout(() => {
        this.$router.push("/");
      }, 500);
    } catch (error) {
      this.isLoading = false;
      notificationService.fail(error.description);
    }
    this.isDisabledClear = false;
  }

  resetModel() {
    this.model = {
      description: null,
      enableUserChoice: false,
      id: null,
      idEvent: null,
      idParent: null,
      idUser: null,
      openingOption: null,
      platform: null,
      title: null,
      duration: null,
      speakerLink: null,
      publicLink: null,
    };
  }

  async init() {
    await this.handleModelLoading();
  }

  afterBaseInit() {
    this.init();
  }

  async created() {
    this.isLoading = true;
    this.platforms = await liveSessionDefaultUserService.platforms();
    this.openingOptions = await liveSessionDefaultUserService.openingOptions();
    this.eventList = await eventService.list();
    this.isLoading = false;
  }
}
</script>

<style lang="scss" scoped>
.liveSession-default-user-detail-page {
  padding: 100px 60px 30px 60px;
  width: 100%;
  height: 100%;
  background-color: #f6f8fa;

  &__form {
    padding: 20px;
  }

  &__back {
    margin: 20px;
    text-decoration: none;
    color: #ebebeb;
    &:hover {
      color: #b9b9b9;
    }
  }

  &__delete {
    margin-top: 25px;
  }
}

.v-card {
  padding: 15px;
}
</style>
