<template>
  <div class="navbar-default-data" v-if="showAppbar && isLogged()">
    <h1 class="navbar-default-data__title">Dati Default</h1>
    <div class="navbar-default-data__btn-wrapper">
    <v-btn class="navbar-default-data__v-btn-add-event" @click="callbackOnBtn" elevation="0">
      <v-icon>mdi-floppy</v-icon>
      <span class="navbar-default-data__add-event-label">Salva</span>
    </v-btn>
    <DropdownMenu />
    </div>
  </div>
</template>

<script>
import { Utility } from "@/lib/Utility";
import Languages from "@/mixins/languages.js";
import Component, { mixins } from "vue-class-component";
import { localStorageService } from "@/services/localStorageService.js";
import Logo from "@/components/logo";
import CountryFlag from "vue-country-flag";
import Notification from "@/components/dom/notification";
import DropdownMenu from "@/components/navbar/DropdownMenu";

@Component({
  components: {
    Logo,
    CountryFlag,
    Notification,
    DropdownMenu
  },
  props: {
    callbackOnBtn: Function
  }
})
export default class NavbarDefaultData extends mixins(Languages) {

  get showAppbar() {
    return this.$route.meta?.showAppbar;
  }

  recomputeFlags() {
    this.recomputeFlagId = Utility.uid("ab");
  }

  isLogged() {
    return !!localStorageService.getLoggedUser();
  }

  selectLanguage(language, $event) {
    $event.preventDefault();
    this.setCurrLang(language);
  }

  isActive(language) {
    const lang = this.currLang();
    return language.id === lang?.id;
  }
}
</script>

<style lang="scss" scoped>
.navbar-default-data {
  background-color: #F6F8FA !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 70px;

  &__v-btn-add-event {
    background-color: #3EFDBF !important;
    color: #000 !important;
    border-radius: 3px;
    margin-left: 10px;
  }

  &__v-btn {
    background-color: #EBEFFA !important;
    color: #2B6EFB !important;
    border-radius: 3px;
    margin-left: 10px;
    border: 1px solid #B2C9FA;
  }

  &__add-event-label {
    padding-left: 10px;
  }

  &__title {
    color: #000;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
  }
}
</style>