import { eventBus } from "@/lib/EventBus";

class NotificationService {
  success(message) {
    eventBus.$emit("notifySuccess", message);
  }

  fail(error) {
    eventBus.$emit("notifyFail", error);
  }
}

export const notificationService = new NotificationService();
