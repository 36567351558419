import { ApiHandler } from "@/lib/ApiHandler";

class LiveSessionDefaultUserService extends ApiHandler {
  constructor() {
    super("/api/liveSessionDefaultUser");
  }

  async read(idLiveSessionDefaultUser) {
    return await this.get(idLiveSessionDefaultUser)
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async readByUserId(userId) {
    return await this.get(`byIdUser/${userId}`)
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async create(body) {
    return await this.post("", body)
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async update(idLiveSessionDefaultUser, body) {
    return await this.put(`${idLiveSessionDefaultUser}`, body)
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async delete(idLiveSessionDefaultUser) {
    return await this.put(`delete/${idLiveSessionDefaultUser}`)
      .then(res => res.json())
      .then(res => {
        return res.result;
      });
  }

  async platforms() {
    return await this.get("platforms")
      .then(res => res.json())
      .then(res => res.result);
  }

  async openingOptions() {
    return await this.get("openingOptions")
      .then(res => res.json())
      .then(res => res.result);
  }
}

export const liveSessionDefaultUserService = new LiveSessionDefaultUserService();
